import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  buttonGreen: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '8px',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  imageContainer: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'rgba(1,1,1,0.2)',
    position: 'relative',
    marginRight: 15,
    flex: 'none',
  },
  tocAction: {
    borderRadius: 10,
    background: 'rgba(1,1,1,0.1)',
    height: '100%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  bordered: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  projectAvatar: {
    width: '40px',
    height: '40px',
    background: 'rgba(1,1,1,0.2)',
    objectFit: 'none',
    borderRadius: 25,
  },
  padRight: {
    paddingRight: '111w.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
}));

const BottomCard = ({ index, amount, label, variation }) => {
  let classes = useStyles();

  const renderChit = () => {
    if (variation.includes('%')) {
      return (
        <Grid
          item
          container
          style={{
            borderRadius: 4,
            fontSize: 10,
            maxWidth: '15%',
            background: 'white',
            color: variation.includes('+') ? '#81B734' : '#EC1717',
            height: 20,
          }}
          justify="center"
          alignItems="center"
        >
          {variation}
        </Grid>
      );
    }
    return (
      <Grid
        item
        container
        style={{
          borderRadius: 4,
          fontSize: 10,
          maxWidth: '15%',
          height: 20,
        }}
        justify="center"
        alignItems="center"
      >
        <img src={variation} alt="placeholder" />
      </Grid>
    );
  };
  return (
    <Grid
      item
      style={{
        width: '20%',
        padding: '10px',
      }}
    >
      <Grid
        item
        container
        style={{
          backgroundColor: 'RGBA( 129, 183, 52, .5 )',
          padding: 10,
          borderRadius: 10,
          marginBottom: '1em',
          boxShadow: '0px 6px 10px 0px #DBE9F680',
          height: '81px',
        }}
      >
        <Grid item container style={{ flexGrow: 1, maxWidth: '85%' }}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>
              {amount}
            </Typography>
          </Grid>
          <Typography style={{ fontSize: 10 }}>{label}</Typography>
        </Grid>
        {renderChit()}
      </Grid>
    </Grid>
  );
};

export const bottomCardDataMock = [
  {
    amount: 'Rs. 343,000',
    label: 'Cost of Poor Quality',
    variation: '-7%',
  },
  {
    amount: 'Rs. 1,000,000',
    label: 'Workmanship Issues',
    variation: '-3%',
  },
  {
    amount: 'Rs. 904,000',
    label: 'Skill Value',
    variation: '-5%',
  },
  {
    amount: 'Rs. 904,000',
    label: 'Certified Value',
    variation: '-5%',
  },
  {
    amount: 'Rs. 904,000',
    label: 'Paid Value',
    variation: '-5%',
  },
];
export default BottomCard;
