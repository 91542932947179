import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { contractorWorkHistoryTableStyle } from '../styles/theme.contractorLis';
import WorkHistoryTable from 'src/components/worker/WorkerHistoryTable';

const ContractorWorkHistoryTable = ({ workHistory }) => {
  let classes = contractorWorkHistoryTableStyle();
  let isLoading = false;
  let filteredArray = [1,1,1,1];
  let workerHistoryList = [1,1,1,1];
  return (
    <Grid item container className={classes.border}>
      <Grid item container alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          align="center"
          className={classes.paddingBottom}
        >
          {' '}
          Work History
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <Typography align="center">Fetching Details ... </Typography>
        ) : (
          <TableContainer style={{ marginTOp: '1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Client
                  </TableCell>{' '}
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Project Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    SOW
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Order Value
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Period
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredArray?.length ? (
                  filteredArray?.map((val, index) => (
                    <TableRow hover key={val?.jobId}>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        ABC Client
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        ABC Project
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        General Labour
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        Order value
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        Period
                      </TableCell>
                    </TableRow>
                  ))
                ) : workerHistoryList.length === 0 ? (
                  <TableRow>No Search Found!</TableRow>
                ) : (
                  workerHistoryList?.map((val, index) => (
                    <TableRow hover key={val?.jobId}>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        ABC Client
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        ABC Project
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        General Labour
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        Order value
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        Period
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
export default ContractorWorkHistoryTable;
