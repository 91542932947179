import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import Page from 'src/components/Page';
import TopCard, { topCardDataMock } from './components/TopCard';
import FilterContainer from './components/FiltersContainer';
import ContractorList from './components/ContractorList';
import BottomCard, { bottomCardDataMock } from './components/BottomCard';
import CreateContractorModal from './components/CreateContractorModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  gridContainer: {
    paddingBottom: '.9rem',
  },
  gridItem: {
    background: 'white',
    padding: '1em',
    borderRadius: '10px',
  },
  filterContainer: {
    backgroundColor: '#E9EEF2',
    borderRadius: '11px 11px 0 0',
  },
  buttonGreen: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '8px',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  imageContainer: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'rgba(1,1,1,0.2)',
    position: 'relative',
    marginRight: 15,
    flex: 'none',
  },
  tocAction: {
    borderRadius: 10,
    background: 'rgba(1,1,1,0.1)',
    height: '100%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  bordered: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  projectAvatar: {
    width: '40px',
    height: '40px',
    background: 'rgba(1,1,1,0.2)',
    objectFit: 'none',
    borderRadius: 25,
  },
  padRight: {
    paddingRight: '0.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
}));
const Header = ({
  classes,
  topCardDataMock,
  bottomCardDataMock,
  openCreateContractorModal,
}) => {
  return (
    <Grid item container xs={12}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.gridContainer}
      >
        <Grid item xs={3}>
          <Typography variant="h6">All Contractors</Typography>
        </Grid>

        <Grid item>
          <Button
            className={classes.buttonPurpleOpacity}
            onClick={openCreateContractorModal}
          >
            + Add New Contractor
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {topCardDataMock?.map((item, index) => (
          <Grid item xs={3} key={index + 1000}>
            <TopCard val={item} index={item.id} />
          </Grid>
        ))}

        <Grid container>
          {bottomCardDataMock?.map((item, index) => (
            <BottomCard
              key={index + 100}
              index={item.id}
              amount={item.amount}
              label={item.label}
              variation={item.variation}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
const ContractorListIndex = () => {
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });
  const [openModal, setOpenModal] = useState(false);

  let classes = useStyles();

  return (
    <Page className={classes.root} id="zoom-level" title="Contractor">
      <CreateContractorModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <Container maxWidth={false}>
        <Header
          classes={classes}
          topCardDataMock={topCardDataMock}
          bottomCardDataMock={bottomCardDataMock}
          openCreateContractorModal={() => setOpenModal(true)}
        />

        <Grid container className={classes.gridItem}>
          <Grid container className={classes.filterContainer}>
            <Grid item xs={12}>
              <FilterContainer filters={filters} setFilters={setFilters} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ContractorList filters={filters} setFilters={setFilters} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ContractorListIndex;
