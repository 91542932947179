import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { contractorProfileStyle } from './styles/theme.contractorLis';
import Page from 'src/components/Page';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router';
import ContractorWorkHistoryTable from './components/ContractorWorkHistoryTable';

const ContractorProfile = () => {
  const classes = contractorProfileStyle();

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    firstName: yup.string().required('Full name is required'),
    companyName: yup.string().required('Address is required'),
    panNo: yup.string().required('Address is required'),
    billingAddress: yup.string().required('Address is required'),
    phoneNumber: yup.string().required('Address is required'),
    scopeOfWork: yup.string().required('Address is required'),
    accountType: yup.string().required('Address is required'),
  });
  var formik = useFormik({
    initialValues: {
      firstName: 'Vinod',
      lastName: 'Kumar',
      entityType: 0,
      companyName: 'ABC PVT',
      panNo: 'ABCDE1234F',
      billingAddress: 'This is the address',
      GSTIN: '29ABCDE1234F1Z5',
      aadharNo: '1234 5678 9012',
      phoneNumber: '+91 98765 43210',
      email: 'vinod.kumar@abcpvt.com',
      accountNumber: '812738712376',
      IFSCCode: 'HDFC0001234',
      accountType: 0,
      scopeOfWork: 0,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      console.log(values);
    },
  });

  //!METHODS
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Contractor">
      <Grid
        container
        style={{
          padding: '0 2rem',
        }}
      >
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton>
                <Grid item style={{ color: 'black' }} onClick={handleBack}>
                  <ArrowBackIcon color="black" fontSize="default" />
                </Grid>
              </IconButton>
            </Grid>
            <Grid item>
              <Button className={classes.buttonPurpleOpacity}>Edit</Button>
            </Grid>
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              style={{
                padding: '1rem 10px',
                borderRadius: '5px',
                position: 'relative',
                margin: '10px 0',
                background:
                  'linear-gradient(white, white) padding-box, linear-gradient(45deg, #81B734, #A179F2) border-box',
                border: '1px solid transparent',
              }}
            >
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    label="FIRST NAME*"
                    fullWidth
                    variant="filled"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label="LAST NAME (Optional)"
                    fullWidth
                    variant="filled"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    select
                    label="ENTITY TYPE"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="entityType"
                    name="entityType"
                    value={formik.values.entityType}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.entityType &&
                      Boolean(formik.errors.entityType)
                    }
                    helperText={
                      formik.touched.entityType && formik.errors.entityType
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value={0} style={{ fontFamily: 'Roboto' }}>
                      Proprietor/ Orgranisation
                    </MenuItem>
                    <MenuItem value={1} style={{ fontFamily: 'Roboto' }}>
                      A
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label="Company Name/Proprietor's Name*"
                    fullWidth
                    variant="filled"
                    id="companyName"
                    name="companyName"
                    value={formik.values.companyName}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    label="PAN NO*"
                    fullWidth
                    variant="filled"
                    id="panNo"
                    name="panNo"
                    value={formik.values.panNo}
                    //         onChange={formik.handleChange}
                    error={formik.touched.panNo && Boolean(formik.errors.panNo)}
                    helperText={formik.touched.panNo && formik.errors.panNo}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label="BILLING ADDRESS*"
                    fullWidth
                    variant="filled"
                    id="billingAddress"
                    name="billingAddress"
                    value={formik.values.billingAddress}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.billingAddress &&
                      Boolean(formik.errors.billingAddress)
                    }
                    helperText={
                      formik.touched.billingAddress &&
                      formik.errors.billingAddress
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    label="GSTIN (Optional)"
                    fullWidth
                    variant="filled"
                    id="GSTIN"
                    name="GSTIN"
                    value={formik.values.GSTIN}
                    //         onChange={formik.handleChange}
                    error={formik.touched.GSTIN && Boolean(formik.errors.GSTIN)}
                    helperText={formik.touched.GSTIN && formik.errors.GSTIN}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label="AADHAR NO (Optional)"
                    fullWidth
                    variant="filled"
                    id="aadharNo"
                    name="aadharNo"
                    value={formik.values.aadharNo}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.aadharNo && Boolean(formik.errors.aadharNo)
                    }
                    helperText={
                      formik.touched.aadharNo && formik.errors.aadharNo
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    label="PHONE NUMBER*"
                    fullWidth
                    variant="filled"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label="EMAIL (Optional)"
                    fullWidth
                    variant="filled"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    //         onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    label="ACCOUNT NUMBER (Optional)"
                    fullWidth
                    variant="filled"
                    id="accountNumber"
                    name="accountNumber"
                    value={formik.values.accountNumber}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.accountNumber &&
                      Boolean(formik.errors.accountNumber)
                    }
                    helperText={
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    readOnly
                    size="small"
                    label="IFSC CODE (Optional)"
                    fullWidth
                    variant="filled"
                    id="IFSCCode"
                    name="IFSCCode"
                    value={formik.values.IFSCCode}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.IFSCCode && Boolean(formik.errors.IFSCCode)
                    }
                    helperText={
                      formik.touched.IFSCCode && formik.errors.IFSCCode
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    size="small"
                    select
                    label="ACCOUNT TYPE*"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="accountType"
                    name="accountType"
                    value={formik.values.accountType}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.accountType &&
                      Boolean(formik.errors.accountType)
                    }
                    helperText={
                      formik.touched.accountType && formik.errors.accountType
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value={0} style={{ fontFamily: 'Roboto' }}>
                      General Contractor/SubContractor/Labour
                    </MenuItem>
                    <MenuItem value={1} style={{ fontFamily: 'Roboto' }}>
                      A
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    select
                    label="SCOPE OF WORK"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="scopeOfWork"
                    name="scopeOfWork"
                    value={formik.values.scopeOfWork}
                    //         onChange={formik.handleChange}
                    error={
                      formik.touched.scopeOfWork &&
                      Boolean(formik.errors.scopeOfWork)
                    }
                    helperText={
                      formik.touched.scopeOfWork && formik.errors.scopeOfWork
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value={0} style={{ fontFamily: 'Roboto' }}>
                      General Labour
                    </MenuItem>
                    <MenuItem value={1} style={{ fontFamily: 'Roboto' }}>
                      A
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </form>

          <Grid item container>
            <ContractorWorkHistoryTable />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
export default ContractorProfile;
