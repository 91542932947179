import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  jobsList: null,
  skillsList: null,
  selectedJob: null,
  completeJobLoading: false,
  filteredJobList: null,
  jobWorkersLoading: false,
  jobWorkers: null,
  jobBySkillList: null,
  jobBySkillLoading: false,
};

const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    gettingJobs(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingJobsSuccess(state, action) {
      state.jobsList = action.payload;
      state.loading = false;
      state.error = null;
      state.filteredJobList = null;
    },
    gettingJobsSuccessFilter(state, action) {
      state.loading = false;
      state.error = null;
      state.filteredJobList = action.payload;
      // state.jobsList = null;
    },
    gettingJobsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    creatitngJobs(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatitngJobsSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    creatitngJobsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingSkills: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    gettingSkillsSuccess: (state, action) => {
      state.skillsList = action.payload;
      state.loading = false;
      state.error = null;
    },
    gettingSkillsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    selectingJob(state, action) {
      state.selectedJob = action.payload;
    },
    completingJob: (state, action) => {
      state.completeJobLoading = true;
      state.error = null;
    },
    completingJobSuccess: (state, action) => {
      state.completeJobLoading = false;
      state.error = null;
    },
    completingJobFailure: (state, action) => {
      state.completeJobLoading = false;
      state.error = action.payload;
    },
    gettingJobWorkers: (state, action) => {
      state.jobWorkersLoading = true;
      state.error = null;
    },
    gettingJobWorkersSuccess: (state, action) => {
      state.jobWorkersLoading = false;
      state.jobWorkers = action.payload;

      state.error = null;
    },
    gettingJobWorkersFailure: (state, action) => {
      state.jobWorkersLoading = false;
      state.error = action.payload;
    },
    gettingJobBySkillList: (state, action) => {
      state.jobBySkillLoading = true;
      state.error = null;
    },
    gettingJobBySkillListSuccess: (state, action) => {
      state.jobBySkillLoading = false;
      state.jobBySkillList = action.payload;

      state.error = null;
    },
    gettingJobBySkillListFailure: (state, action) => {
      state.jobBySkillLoading = false;
      state.error = action.payload;
      state.jobBySkillList = null;
    },
  },
});
const {
  gettingJobs,
  gettingJobsSuccess,
  gettingJobsSuccessFilter,
  gettingJobsFailure,
  creatitngJobs,
  creatitngJobsSuccess,
  creatitngJobsFailure,
  gettingSkills,
  gettingSkillsSuccess,
  gettingSkillsFailure,
  selectingJob,
  completingJob,
  completingJobSuccess,
  completingJobFailure,
  gettingJobWorkers,
  gettingJobWorkersSuccess,
  gettingJobWorkersFailure,
  gettingJobBySkillList,
  gettingJobBySkillListSuccess,
  gettingJobBySkillListFailure,
} = slice.actions;
export const jobReducer = (state) => state.jobs;

export const getJobs =
  (createdBy = 0) =>
  async (dispatch) => {
    dispatch(gettingJobs());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Job/getall?createdBy=${createdBy}`
      );
      if (response.status === 200) {
        if (createdBy !== 0) {
          dispatch(gettingJobsSuccessFilter(response.data));
        } else {
          dispatch(gettingJobsSuccess(response.data));
        }
      } else if (response.status === 500) {
        dispatch(gettingJobsFailure('Job list service is down'));
      } else {
        dispatch(gettingJobsFailure('Something went wrong while getting jobs'));
      }
      return response;
    } catch (e) {
      console.log(e);
      dispatch(gettingJobsFailure('Something went wrong while getting users!'));
    }
  };
//!

export const getSkills = () => async (dispatch) => {
  dispatch(gettingSkills());
  try {
    const response = await axios.get(`${config.baseUrl}/app/Skills`);
    if (response.status === 200) {
      dispatch(gettingSkillsSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingSkillsFailure('Something went wrong while getting users!'));
  }
};

export const updateJob = (worker) => async (dispatch) => {
  dispatch(creatitngJobs());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Job/createjob`,
      worker,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.status === 200) {
      dispatch(creatitngJobsSuccess(response.data));
    } else {
      dispatch(
        creatitngJobsFailure(
          'Something went wrong while creating/updaing project!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      creatitngJobsFailure(
        'Something went wrong while creating/updaing project!'
      )
    );
    return e;
  }
};

export const selectJob = (job) => async (dispatch) => {
  dispatch(selectingJob(job));
};

export const completeJob = (jobId) => async (dispatch) => {
  dispatch(completingJob());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Job/complete?jobId=${jobId}`
    );

    if (response.status === 200) {
      dispatch(completingJobSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      completingJobFailure('Something went wrong while completing job!')
    );
    return e;
  }
};

export const getJobWorkers = (jobId) => async (dispatch) => {
  dispatch(gettingJobWorkers());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Job?jobId=${jobId}`
    );
    if (response.status === 200) {
      dispatch(gettingJobWorkersSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingJobWorkersFailure('Something went wrong while getting users!')
    );
  }
};

export const getJobSwitchWorkers =
  (jobId, search = '') =>
  async (dispatch) => {
    dispatch(gettingJobWorkers());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Job/self-active-workers?JobId=${jobId}&PageNumber=1&PageSize=500&search=${search}`
      );
      if (response.status === 200) {
        dispatch(gettingJobWorkersSuccess(response.data));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingJobWorkersFailure('Something went wrong while getting users!')
      );
    }
  };

export const getJobsBySkillset =
  (projectId, skillId, skillTypeId) => async (dispatch) => {
    dispatch(gettingJobBySkillList());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Job/match-jobs-by-skills?projectId=${projectId}&skillId=${skillId}&skillTypeId=${skillTypeId}`
      );
      if (response.status === 200) {
        dispatch(gettingJobBySkillListSuccess(response.data.result));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingJobBySkillListFailure(
          'Something went wrong while getting jobs by skillset!'
        )
      );
    }
  };

export default slice.reducer;
