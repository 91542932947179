import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearWorkerHistory,
  getWorkerHistory,
  setWorkerForDetail,
  switchWorkerJob,
  workersReducer,
} from 'src/redux/workers';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import {
  getJobs,
  getJobsBySkillset,
  getSkills,
  jobReducer,
} from 'src/redux/jobs';
// import tickSquare from "../../assests/Tick Square.png";
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import WorkHistoryTable from 'src/components/worker/WorkerHistoryTable';
import GetAppIcon from '@material-ui/icons/GetApp';
// import { Document, Page, StyleSheet } from "@react-pdf/renderer";
// import { PDFViewer } from "@react-pdf/renderer";
import placeholder from '../../assests/worker2.png';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GBImage from '../../assests/workerIdPDFBG.png';
// import k2k from "../../assests/k2k.png";
// import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useAccessPerView from 'src/common/Access';
import WorkIcon from '@material-ui/icons/Work';
import toast from 'react-hot-toast';
import { CheckBox } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyleHeading: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '22px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle1: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    // color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      // fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#fff',
    },
  },
  floatingLabelFocusStyle: {
    color: '#F9F9F9',
  },
  input1: {
    borderRadius: '10px',
    // backgroundColor: 'rgba(126, 131, 134, 0.1)',
    backgroundColor: 'transparent',
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(126, 131, 134, 0.1)',
    // backgroundColor: 'transparent',
  },
  profilePictureContainer: {
    paddingLeft: '1rem',
    paddingTop: '2rem',
    // position: 'absolute',
    // top: '50%',
  },
  greenCard: {
    backgroundColor: '#81B734',
    height: '270px',
    borderRadius: '11px',
    marginRight: '10px',
    // position: 'relative',
  },
  placeholderStyle: {
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
  hRow: {
    marginLeft: '25px',
    borderLeft: '1px solid rgba(200, 200, 200, 1)',
    padding: '10px 0',
    height: '40px',
  },
  skillAndAdharFieldContainer: {
    paddingLeft: '4rem',
    paddingTop: '2rem',
    // border: '2px solid red',
  },
  whiteFieldContainer: {
    paddingLeft: '1rem',
    paddingTop: '5rem',
  },
  headerCellText1: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '12px',
  },
  selectedRow: {
    backgroundColor: 'rgba(161, 120, 242, 0.90)',
  },
  [theme.breakpoints.down('sm')]: {
    greenCard: {
      backgroundColor: '#81B734',
      height: '550px',
      borderRadius: '11px',
      // marginRight: '10px',
      // position: 'relative',
    },
    hRow: {
      marginLeft: '15px',
      borderLeft: '1px solid #fff',
      padding: '10px 0',
      height: '30px',
    },
    skillAndAdharFieldContainer: {
      paddingLeft: '1rem',
      paddingTop: '1rem',
    },
    profilePictureContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
      // position: 'absolute',
      // top: '50%',
    },
    whiteFieldContainer: {
      paddingLeft: '0', //'5.5rem',
      paddingTop: '0 ', //'1rem',
    },
    adharCardFiledContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
    },
  },
}));
const WorkerDetail = () => {
  const [listOfJobs, setListOfJobs] = useState(null);
  const [job, setJob] = useState(null);
  const [isIDCardVisivle, setIsIDCardVisible] = useState(false);
  const [projectPhoto, setProjectPhoto] = useState(null);
  const { isViewMode } = useAccessPerView('WORKER_ONBOARDING');
  const [isChangeJobBVisible, setIsChangeJobVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  //! INSTANCES
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();

  // const user = JSON.parse(localStorage.getItem("userDetails"));

  //! SELECTORS
  const {
    selectedWorker,
    workerHistoryList,
    selectedProject,
    workerHistoryLoading,
    selectedWorkerProject,
    jobSwitchingLoading,
  } = useSelector(workersReducer);
  const { projectClassificationList } = useSelector(projectReducer);
  const { jobsList, skillsList, jobBySkillList, jobBySkillLoading } =
    useSelector(jobReducer);

  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
    if (!skillsList) {
      dispatch(getSkills());
    }
    // if (!workerHistoryList) {
    dispatch(getWorkerHistory(selectedWorker?.workerId));
    // }

    if (!jobsList) {
      dispatch(getJobs());
    }
  }, []);

  useEffect(() => {
    if (jobsList?.length && selectedProject?.projectId) {
      // Filter jobs related to the selected project and update the state
      const filteredJobs = jobsList?.filter(
        (job) => job.projectId === selectedProject.projectId
      );
      setListOfJobs(filteredJobs);

      // Set the selected job's name and description
      const selectedJob = selectedWorker?.workerJobs[0];
      if (selectedJob) {
        const data = filteredJobs?.find(
          (job) => job.jobId === selectedJob.jobId
        );
        if (data) {
          setJob(`${data.jobName}-${data.description}`);
        }
      }
    }
  }, [jobsList, selectedProject, selectedWorker?.workerJobs]);

  //! ----------
  const handleBack = () => {
    dispatch(setWorkerForDetail(null));
    dispatch(clearWorkerHistory());
    navigate(-1);
  };

  function addSpaces(str) {
    // Using regex to insert a space after every 4 characters
    return str.replace(/(.{4})/g, '$1 ');
  }

  const getProjectPhoto = (projectId) => {
    let data = projectClassificationList?.filter(
      (item) => item.projectId === projectId
    );

    return `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${data[0]?.url}`;
  };
  const downloadPDF = async () => {
    const input = componentRef.current;
    const inputHeight = input.offsetHeight;
    const inputWidth = input.offsetWidth;

    // Delay execution for 2 seconds (adjust this as needed)
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    html2canvas(input, {
      allowTaint: false,
      useCORS: true,
      height: inputHeight,
      width: inputWidth,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', [inputWidth, inputHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, inputWidth, inputHeight);
      try {
        pdf.save(
          `worker_ID_card_${
            selectedWorker?.workerId ? '0000' + selectedWorker?.workerId : 'N/A'
          }.pdf`
        );
      } catch (error) {
        console.log('Error saving PDF:', error);
      }
    });
  };

  const openChangeJobModal = () => {
    setIsChangeJobVisible(true);
    dispatch(
      getJobsBySkillset(
        selectedProject?.projectId,
        selectedWorker?.workerSkills[0]?.skillId,
        selectedWorker?.workerSkills[0]?.skillTypeId
      )
    );
  };
  const selectARow = (row) => {
    if (row?.jobId === selectedRow?.jobId) {
      return setSelectedRow(null);
    }
    setSelectedRow(row);
  };
  const handleAssignJob = async () => {
    if (!selectedRow) {
      return toast.error('Please select a job to assign!');
    }
    let toastLoading = toast.loading('Assigning a job...');

    let resp = await dispatch(
      switchWorkerJob({
        jobId: selectedRow?.jobId,
        workerList: [selectedWorker?.workerId],
      })
    );
    if (resp.status === 200) {
      toast.dismiss(toastLoading);
      toast.success('Job assigned successfully!');
      setIsChangeJobVisible(false);
      setSelectedRow(null);
    } else {
      toast.dismiss(toastLoading);
      toast.error(resp.data.error);
    }
  };

  return (
    <>
      {selectedWorker && (
        <Grid container style={{ zoom: '90%' }}>
          <Dialog
            open={isChangeJobBVisible}
            onClose={() => {
              setSelectedRow(null);
              setIsChangeJobVisible(false);
            }}
            maxWidth
            fullWidth
          >
            <DialogTitle>
              <Typography variant="h6">Change Worker Job</Typography>
              <Typography
                variant="h6"
                style={{ fontSize: '15px', color: '#00000070' }}
              >
                Select any job from below:
              </Typography>
            </DialogTitle>
            <DialogContent>
              {jobBySkillLoading ? (
                <Typography align="center">Fetching Details ... </Typography>
              ) : (
                <TableContainer style={{ marginTOp: '1rem' }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          size="small"
                          align="left"
                          // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          className={classes.headerCellText1}
                        >
                          Job ID
                        </TableCell>{' '}
                        <TableCell
                          size="small"
                          align="left"
                          // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          className={classes.headerCellText1}
                        >
                          Job Name
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          className={classes.headerCellText1}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          className={classes.headerCellText1}
                        >
                          Skill Level
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {jobBySkillList?.length === 0 ||
                      jobBySkillList?.length === 0 ? (
                        <TableRow>No Data Found!</TableRow>
                      ) : (
                        jobBySkillList?.map((val, index) => (
                          <TableRow
                            style={{
                              cursor: 'pointer',
                            }}
                            key={index + 1}
                            onClick={() => selectARow(val)}
                            className={
                              selectedRow?.jobId === val.jobId &&
                              classes.selectedRow
                            }
                          >
                            <TableCell
                              align="left"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {selectedRow?.jobId === val.jobId && (
                                <CheckBox
                                  style={{ width: '18px', height: '20px' }}
                                  value="checked"
                                ></CheckBox>
                              )}
                              {'  '}
                              <p style={{ paddingLeft: '5%' }}>
                                {val?.jobId ?? 'N/A'}
                              </p>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val?.skillName ?? 'N/A'}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val?.description ?? 'N/A'}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val?.skillTypeId ?? 'N/A'}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Button
                  className={classes.buttonGreen}
                  onClick={() => {
                    setSelectedRow(null);
                    setIsChangeJobVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.buttonPurpleOpacity}
                  style={{ marginLeft: '1rem' }}
                  onClick={handleAssignJob}
                  disabled={jobSwitchingLoading}
                >
                  Assign Job
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>

          <Dialog
            open={isIDCardVisivle}
            onClose={() => setIsIDCardVisible(false)}
          >
            <DialogTitle>
              <Grid container justify="flex-end" alignItems="center">
                <Button
                  onClick={downloadPDF}
                  className={classes.buttonGreen}
                  startIcon={<GetAppIcon />}
                  // onClick={savePdf}
                  size="small"
                >
                  Download
                </Button>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ width: '350px' }}>
              <Grid container justify="center" ref={componentRef}>
                <Grid
                  item
                  style={{
                    overflow: 'hidden',
                    borderRadius: '28px',
                    position: 'relative',
                    borderBottom: '3px solid #81B734',
                  }}
                >
                  <img src={GBImage} alt="Worker ID Card" />
                  <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    style={{
                      position: 'absolute',
                      top: 10,
                      padding: '0 1rem',
                    }}
                  >
                    {' '}
                    <Typography
                      variant="subtitle1"
                      style={{ color: '#FFFFFF' }}
                    >
                      {selectedWorker?.workerId
                        ? '0000' + selectedWorker?.workerId
                        : 'N/A'}
                    </Typography>
                    <img
                      src={getProjectPhoto(selectedWorkerProject)}
                      // src={imageSrc}
                      alt="project logo"
                      style={{
                        width: '55px',
                        height: '55px',
                        borderRadius: '12px',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      position: 'absolute',
                      top: '23%',
                      left: 58,
                      width: '200px',
                    }}
                    container
                  >
                    <Grid container item>
                      {selectedWorker?.workerDocuments
                        ?.filter(
                          (worker) => worker.documentId === 'ProfilePicture'
                        )
                        ?.map((obj, index) => (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            container
                            justify="center"
                          >
                            {obj?.documentId === 'ProfilePicture' && (
                              <img
                                src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                                alt="Worker visual"
                                style={{
                                  width: '150px',
                                  height: '150px',
                                  // height: "90px",

                                  borderRadius: '8px',
                                  textAlign: 'center',
                                  marginTop: '1rem',
                                }}
                              />
                            )}
                          </Grid>
                        ))}
                      {selectedWorker?.workerDocuments?.filter(
                        (worker) => worker.documentId === 'ProfilePicture'
                      ).length === 0 && (
                        <Grid item xs={12}>
                          <img
                            src={placeholder}
                            alt="Worker visual"
                            style={{
                              width: '150px',
                              height: '150px',
                              // height: "90px",
                              borderRadius: '8px',
                              textAlign: 'center',
                              marginTop: '1rem',
                            }}
                          />
                        </Grid>
                      )}
                      {selectedWorker?.isVerified ? (
                        <Grid
                          item
                          container
                          justify="center"
                          alignItems="center"
                        >
                          <Typography
                            align="center"
                            variant="subtitle1"
                            style={{
                              color: '#81B734',
                              fontSize: '11px',
                              marginRight: '5px',
                            }}
                          >
                            VERIFIED{'  '}
                          </Typography>
                          <CheckCircleIcon
                            style={{ color: '#81B734', fontSize: '14px' }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid item container style={{ marginTop: '4rem' }}>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',

                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.fullName ?? 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',
                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          DOB:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.dateOfBirth
                            ? moment(selectedWorker?.dateOfBirth).format('LL')
                            : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',

                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          Gender:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.gender ?? 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',
                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          Skill:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.workerSkills[0]?.skill.name ?? 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',

                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          Skill level:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.workerSkills[0]?.skillTypeId ??
                            'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',
                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          Adhar:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.identityNumber
                            ? addSpaces(selectedWorker?.identityNumber)
                            : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid
                      item
                      container
                      style={{
                        padding: "0 1rem",

                        marginBottom: "5px",
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: "13px", fontWeight: 300 }}
                        >
                          Contractor:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: "13px" }}>
                          N/A
                        </Typography>
                      </Grid>
                    </Grid> */}
                    <Grid
                      item
                      container
                      style={{
                        padding: '0 1rem',
                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          style={{ fontSize: '13px', fontWeight: 300 }}
                        >
                          Address:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography style={{ fontSize: '13px' }}>
                          {selectedWorker?.address ?? 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            alignItems="center"
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Grid item xs={4} container alignItems="center">
              <IconButton>
                <Grid
                  item
                  // to='..'
                  style={{ color: 'black' }}
                  onClick={handleBack}
                >
                  <ArrowBackIcon color="black" fontSize="default" />
                </Grid>
              </IconButton>
              <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
                {selectedWorker?.fullName ?? 'N/A'}
              </Typography>
            </Grid>
            {/* {user?.user?.userTypeId === 'OnboardingOfficer' && ( */}

            <Grid
              container
              md={4}
              alignItems="center"
              justify="flex-end"
              style={{ marginRight: '1rem' }}
            >
              <Grid item style={{ marginRight: '1rem' }}>
                {/* <Link to="/app/workerprofile/edit"> */}
                <Button
                  className={classes.buttonGreen}
                  startIcon={<WorkIcon />}
                  onClick={openChangeJobModal}
                >
                  Change Job
                </Button>
              </Grid>
              <Grid
                item
                // xs={1}
                // container
                // justify="flex-end"
                style={{ marginRight: '1rem' }}
              >
                {/* <Link to="/app/workerprofile/edit"> */}
                <Button
                  className={classes.buttonGreen}
                  startIcon={<PictureAsPdfIcon />}
                  onClick={() => setIsIDCardVisible(true)}
                >
                  View ID Card
                </Button>
              </Grid>
              <Grid
                item
                // xs={1}
                // container
                // justify="flex-end"
                // style={{ marginRight: "1rem" }}
              >
                <Link
                  to="/app/workerprofile/edit"
                  className={isViewMode ? classes.disabledLink : ''}
                >
                  <Button
                    className={classes.buttonPurpleOpacity}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </Link>
              </Grid>
            </Grid>
            {/* )} */}
          </Grid>

          <Grid container xs={12} className={classes.greenCard}>
            <Grid
              item
              md={2}
              xs={12}
              // container
              // jusity='center'
              className={classes.profilePictureContainer}
            >
              {selectedWorker?.workerDocuments
                ?.filter((worker) => worker.documentId === 'ProfilePicture')
                ?.map((obj, index) => (
                  <Grid item xs={12} key={index}>
                    {obj?.documentId === 'ProfilePicture' && (
                      <img
                        src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                        alt="Worker visual"
                        style={{
                          width: '250px',
                          height: '230px',
                          // height: "90px",
                          borderRadius: '8px',
                          textAlign: 'center',
                          marginTop: '1rem',
                        }}
                      />
                    )}
                  </Grid>
                ))}

              {selectedWorker?.workerDocuments?.filter(
                (worker) => worker.documentId === 'ProfilePicture'
              ).length === 0 && (
                <Grid item xs={12}>
                  <img
                    src={placeholder}
                    alt="Worker visual"
                    style={{
                      width: '230px',
                      height: '230px',
                      // height: "90px",
                      borderRadius: '8px',
                      textAlign: 'center',
                      marginTop: '1rem',
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              md={6}
              xs={12}
              className={classes.skillAndAdharFieldContainer}
            >
              <Grid item md={7} xs={8}>
                <TextField
                  className={classes.placeholderStyle}
                  label="ID"
                  fullWidth
                  variant="filled"
                  value={
                    selectedWorker?.workerId
                      ? '0000' + selectedWorker?.workerId
                      : 'N/A'
                  }
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyleHeading} `,
                      root: classes.input1,
                    },
                  }}
                />
              </Grid>

              <Grid item md={7} xs={8}>
                <TextField
                  className={classes.placeholderStyle}
                  label="FULL NAME"
                  fullWidth
                  variant="filled"
                  value={selectedWorker?.fullName ?? 'N/A'}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyleHeading} `,
                      root: classes.input1,
                    },
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                // justify='space-between'
                alignItems="center"
              >
                <Grid item md={5}>
                  <TextField
                    label="SKILL SET"
                    fullWidth
                    variant="filled"
                    className={classes.placeholderStyle}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    value={selectedWorker?.workerSkills[0]?.skill.name ?? 'N/A'}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle1}  `,
                        root: classes.input1,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={1} className={classes.hRow}></Grid>
                <Grid item md={5}>
                  <TextField
                    className={classes.placeholderStyle}
                    label="ADHAR CARD NUMBER"
                    fullWidth
                    variant="filled"
                    // style={{ width: '715px' }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    value={selectedWorker?.identityNumber ?? 'N/A'}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle1}`,
                        root: classes.input1,
                      },
                    }}
                  />
                </Grid>
                <Grid item md={5}>
                  <TextField
                    label="SKILL LEVEL"
                    fullWidth
                    variant="filled"
                    className={classes.placeholderStyle}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    value={
                      selectedWorker?.workerSkills[0]?.skillTypeId ?? 'N/A'
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle1}  `,
                        root: classes.input1,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            // flexDirection='column'
            justify="space-between"
            xs={12}
            className={classes.whiteFieldContainer}
          >
            <Grid
              item
              className={classes.adharCardFiledContainer}
              style={{
                width: '250px',
                height: '150px',
              }}
            >
              {selectedWorker?.workerDocuments
                ?.filter((worker) => worker.documentId === 'IdentityCard')
                ?.map((obj, index) => (
                  <Grid item xs={12} key={index}>
                    <img
                      src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                      alt="Id Card"
                      style={{
                        width: '250px',
                        height: '150px',
                        textAlign: 'center',
                        borderRadius: '10px',
                        marginRight: '10px',
                      }}
                    />
                  </Grid>
                ))}
              {selectedWorker?.workerDocuments.filter(
                (worker) => worker.documentId === 'IdentityCard'
              ).length === 0 && (
                <Grid item xs={12}>
                  <div
                    style={{
                      border: '2px solid #ddd',
                      borderRadius: '10px',
                      width: '250px',
                      height: '150px',
                      display: 'flex',
                      justify: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <p>Please Upload Adhar Card!</p>
                  </div>
                </Grid>
              )}
            </Grid>
            <div className="whiteFieldContainer2nd">
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  label="PHONE"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={'+' + selectedWorker?.phoneNumber ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <PhoneOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item container xs={12} style={{ marginBottom: '1rem' }}>
                <Grid item xs={6}>
                  <TextField
                    label="GENDER"
                    fullWidth
                    variant="filled"
                    style={{ paddingRight: '1rem' }}
                    // style={{ width: '715px' }}
                    value={selectedWorker?.gender ?? 'N/A'}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="DATE OF BRITH"
                    fullWidth
                    variant="filled"
                    // style={{ width: '715px' }}
                    value={
                      selectedWorker?.dateOfBirth
                        ? moment(selectedWorker?.dateOfBirth).format('LL')
                        : 'N/A'
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                      endAdornment: (
                        <InputAdornment>
                          <EventNoteOutlinedIcon
                            style={{ color: 'rgba(168, 175, 183, 1)' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid itme xs={12} style={{ marginBottom: '1rem' }}>
                <TextField
                  label="LOCATION"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={selectedWorker?.address ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <LocationOnOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                <TextField
                  label="BANK NAME"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={selectedWorker?.bankName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <AccountBalanceOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ marginBottom: '1rem' }}
                justify="center"
              >
                <Grid item xs={6}>
                  <TextField
                    label="BANK ACCOUNT NUMBER"
                    fullWidth
                    variant="filled"
                    style={{ paddingRight: '1rem' }}
                    value={selectedWorker?.bankAccountNumber ?? 'N/A'}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="IFSC CODE"
                    fullWidth
                    variant="filled"
                    // style={{ width: '715px' }}
                    value={selectedWorker?.ifscCode ?? 'N/A'}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid container item>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: 600,
                  margin: '20px 0',
                  paddingLeft: '10px',
                }}
                component="h5"
              >
                Work History
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <WorkHistoryTable
                workerHistoryList={workerHistoryList}
                skillsList={skillsList}
                workerHistoryLoading={workerHistoryLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WorkerDetail;
