import React from 'react';
import {
  // Box,
  Button,
  // Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  // Select,
  IconButton,
  Select,
  // MenuItem,
  // Table,
  // TableHead,
  // TableRow,
  // TableCell,
  // TableBody,
  // FormControl,
  // InputLabel,
  OutlinedInput,
  Chip,
  MenuItem,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { pickAUser, usersReducer } from 'src/redux/users';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { getRoles, rolesReducer } from 'src/redux/roles';
import { useEffect } from 'react';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { Box } from 'victory';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    color: '#986FEF',
    padding: '10px 20px',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  commonStyles: {
    fontFamily: 'Roboto',
    marginBottom: '1.9rem',
  },

  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  [theme.breakpoints.down('sm')]: {},
}));

const UserDetail = (props) => {
  //! PROPS
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  //! INSTANCES
  let dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();

  //! SELECTORS
  const { selectedUser: user } = useSelector(usersReducer);
  const { loading, rolesList } = useSelector(rolesReducer);
  const { projectClassificationList } = useSelector(projectReducer);
  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, []);

  //! METHODS
  const handleBack = () => {
    dispatch(pickAUser(null));
    navigate('..');
  };

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const handleEdit = () => {
    navigate('/app/users/edit');
  };

  let data = [];
  user.userProjects.map((item) =>
    projectClassificationList.filter((pro) => {
      if (item.projectId === pro.projectId) {
        data.push(pro);
        // return pro;
      }
    })
  );

  return (
    <Grid
      container
      style={{
        padding: '1rem',
      }}
    >
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        style={{
          marginTop: '1em',
          marginBottom: '1em',
          paddingRight: '10px',
        }}
      >
        <Grid item md={4} xs={12} container alignItems="center">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon color="black" fontSize="default" />
          </IconButton>
          <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
            {user?.fullName ?? 'N/A'}{' '}
          </Typography>
        </Grid>
        <Grid item container justify="flex-end" md={2} xs={12}>
          <Button
            startIcon={<EditIcon />}
            // fullWidth
            className={classes.buttonPurpleOpacity}
            onClick={handleEdit}
            disabled={loading}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      {/* <Grid container item xs={12} justify='flex-start' alignItems='center'> */}
      {/* <Grid item xs={4}>
					<Grid>
						<img
							src='/static/images/bettamintImages/worker2.png'
							alt='project'
							style={{
								width: '100%',
								// height: "90px",
								textAlign: 'center',
							}}
						/>
					</Grid>
				</Grid> */}
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          className={classes.commonStyles}
          // justify='flex-start'
        >
          <TextField
            placeholder="Enter name"
            fullWidth
            variant="filled"
            InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
            autoFocus
            label="FULL NAME"
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
              },
            }}
            value={user?.fullName ?? 'N/A'}
            inputProps={{ style: { fontFamily: 'Roboto' } }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.commonStyles}
          justify="center"
        >
          <TextField
            placeholder="Full Name"
            fullWidth
            variant="filled"
            InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
            autoFocus
            label="USERNAME"
            inputProps={{ style: { fontFamily: 'Roboto' } }}
            //   style={{ width: "715px" }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
              },
            }}
            value={user?.username ?? 'N/A'}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.commonStyles}
        >
          <TextField
            placeholder="Enter name"
            fullWidth
            variant="filled"
            autoFocus
            label="EMAIL ADDRESS"
            InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
            inputProps={{ style: { fontFamily: 'Roboto' } }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
              },
            }}
            value={user?.emailAddress ?? 'N/A'}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.commonStyles}
          justify="center"
        >
          <TextField
            placeholder="Enter name"
            fullWidth
            variant="filled"
            autoFocus
            InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
            label="PROJECT"
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
              },
              style: { fontFamily: 'Roboto' },
            }}
            className={classes.textField}
            value={data.map((item) => item.name)}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.commonStyles}
          justify="center"
        >
          <TextField
            placeholder="Enter name"
            fullWidth
            variant="filled"
            autoFocus
            InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
            label="ROLE"
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
              },
              style: { fontFamily: 'Roboto' },
            }}
            className={classes.textField}
            value={
              rolesList.filter((role) => role.roleId === user?.roleId)[0]?.name
            }
          />
        </Grid>
        {/* <Grid
						container
						item
						xs={10}
						style={{ marginBottom: 13 }}
						justify='center'
					>
						<TextField
							placeholder='Enter name'
							label='RIGHTS'
							fullWidth
							variant='filled'
							//   style={{ width: "715px" }}
							value='Primary'
						/>
					</Grid>
					<Grid
						container
						item
						xs={10}
						style={{ marginBottom: 13 }}
						justify='center'
					>
						<TextField
							placeholder='Enter name'
							label='EMPLOYEE ID'
							fullWidth
							variant='filled'
							//   style={{ width: "715px" }}
							value='6527-5784-251-25'
						/>
					</Grid> */}
      </Grid>
      {/* </Grid> */}
      {/* <Grid
				container
				// direction="column"
				style={{ marginTop: 15, marginBottom: 15 }}
			>
				<Grid item xs={12}>
					<Typography variant='h5' style={{ fontFamily: 'Roboto' }}>
						User History
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={12}
					style={{ width: '100%', marginTop: '1em' }}
				>
					<img
						src='/static/images/bettamintImages/usersearch.png'
						alt='Filter'
						style={{ width: '100%' }}
					/>
				</Grid>
				<Grid
					container
					style={{
						width: '100%',
						background: '#6666660F',
						marginTop: '2em',
						borderRadius: 10,
						height: 130,
					}}
				>
					<Grid style={{ margin: '1em' }}>
						<Typography style={{ fontFamily: 'Roboto', fontSize: 14 }}>
							<b>11:03 AM</b> ---- Logged in to the developer account.
						</Typography>
						<Typography style={{ fontFamily: 'Roboto', fontSize: 14 }}>
							<b>11:03 AM</b> ---- Logged in to the developer account.
						</Typography>
						<Typography style={{ fontFamily: 'Roboto', fontSize: 14 }}>
							<b>11:03 AM</b> ---- Logged in to the developer account.
						</Typography>
						<Typography style={{ fontFamily: 'Roboto', fontSize: 14 }}>
							<b>11:03 AM</b> ---- Logged in to the developer account.
						</Typography>
					</Grid>
				</Grid>
			</Grid> */}
    </Grid>
  );
};

export default UserDetail;
