const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: -8,
    // '& *': {
    //   fontSize: '0.9rem',
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '1rem',
    //   },
    //   [theme.breakpoints.up('lg')]: {
    //     fontSize: '1rem',
    //   },
    // },
  },
  green: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  greenOpacity: {
    color: '#81B734',
    backgroundColor: 'rgba(129, 183, 52, 0.1)',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: 'rgba(129, 183, 52, 0.1)',
    },
  },
  purple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  purpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  justifySpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  justifySpaceAround: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  alignCenter: {
    alignItems: 'center',
  },
  roundContainer: {
    // padding: 15,
    borderRadius: 5,
  },
  label: {
    fontSize: 12,
  },
  text: {
    fontSize: '0.8rem',
  },
  img: {
    marginRight: 15,
  },
  graphContainer: {
    border: '1px solid rgba(1,1,1,0.1)',
    borderRadius: 5,
    position: 'relative',
    overflowY: 'auto',
    background: 'white',
    padding: '20px 0px',
  },
  graph: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
    backgroundColor: 'white',
    '& > *': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  graphTitle: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 15px',
  },

  fontSmall: {
    '& *': {
      fontSize: '0.8rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '0.9rem',
      },
    },
  },
});

export default styles;
