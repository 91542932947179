import {
  Button,
  Grid,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';
import clsx from 'clsx';

import React, { useEffect } from 'react';
import styles from './styles';
import vinodShuklaIMG from '../../assests/ViconShuklaProfile.png';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import WorkOrderChart from './components/WorkOrderChart';
import FieldNotesChart from './components/FieldNotesChart';
import MaterialDetailsChart from './components/MaterialDetailsChart';
import ContractorProductionRate from './components/ContractorProductionRateChart';
import ManPowerChart from './components/ManpowerChart';
import BillingInformationChart from './components/BillingInformationChart';
import { useNavigate } from 'react-router';
//contractors graph changes
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ContractorDetails = (props) => {
  const { classes } = props;

  let dispatch = useDispatch();
  const navigate = useNavigate();

  const { projectClassificationList } = useSelector(projectReducer);

  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, [projectClassificationList]); // eslint-disable-line

  const handleUserProfileClick = () => {
    navigate('/app/contractors/profile');
  };

  const handleBack = () => {
    navigate('/app/contractors ');
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={4} container alignItems="center">
        <IconButton>
          <Grid
            item
            // to='..'
            style={{ color: 'black' }}
            onClick={handleBack}
          >
            <ArrowBackIcon color="black" fontSize="default" />
          </Grid>
        </IconButton>
      </Grid>
      <Grid
        container
        xs={12}
        className={clsx(
          classes.roundContainer,
          classes.green,
          classes.justifySpaceBetween,
          classes.alignCenter
        )}
      >
        <Grid item xs={5} container className={clsx(classes.alignCenter)}>
          <img
            src={vinodShuklaIMG}
            alt="vinod builders"
            className={classes.img}
          />
          <Grid>
            <Typography className={clsx(classes.label, classes.text)}>
              FULL NAME
            </Typography>
            <Grid container alignItems="center">
              <Typography style={{ fontWeight: 'bold' }}>
                Vinod Builders Pvt Ltd.
              </Typography>
              <CheckBoxIcon
                style={{
                  color: '#48C629',
                  height: 25,
                  width: 25,
                  stroke: 'white',
                  marginLeft: 20,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography className={clsx(classes.label, classes.text)}>
            CONTRACTOR ID
          </Typography>
          <Typography
            style={{ fontWeight: 'bold' }}
            className={clsx(classes.text)}
          >
            0991
          </Typography>
        </Grid>

        <Grid
          item
          xs={2}
          container
          justifyContent="flex-end"
          style={{ marginRight: '1rem' }}
        >
          <Button
            className={clsx(classes.label, classes.text, classes.purple)}
            onClick={handleUserProfileClick}
            style={{ textTransform: 'capitalize', padding: '5px 20px' }}
          >
            View Profile
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{
          padding: '1rem 10px',
          borderRadius: '5px',
          position: 'relative',
          margin: '10px 0',
          background:
            'linear-gradient(white, white) padding-box, linear-gradient(45deg, #81B734, #A179F2) border-box',
          border: '1px solid transparent',
        }}
      >
        <Button
          className={clsx(classes.label, classes.text, classes.purple)}
          onClick={() => navigate('/app/workerprofile')}
          style={{ textTransform: 'capitalize', padding: '10px 50px' }}
        >
          Workers
        </Button>
        <Button
          className={clsx(classes.label, classes.text, classes.purple)}
          style={{ textTransform: 'capitalize', padding: '10px 50px' }}
          onClick={() => navigate('/app/productivity')}
        >
          BOQs
        </Button>{' '}
        <Button
          className={clsx(classes.label, classes.text, classes.purple)}
          style={{ textTransform: 'capitalize', padding: '10px 50px' }}
          onClick={() => navigate('/app/inventory')}
        >
          Material
        </Button>{' '}
        <Button
          className={clsx(classes.label, classes.text, classes.purple)}
          style={{ textTransform: 'capitalize', padding: '10px 50px' }}
          onClick={() => navigate('/app/field-notes')}
        >
          Field Notes
        </Button>{' '}
        <Button
          className={clsx(classes.label, classes.text, classes.purple)}
          onClick={() => navigate('/app/billing')}
          style={{ textTransform: 'capitalize', padding: '10px 50px' }}
        >
          Billing
        </Button>
      </Grid>
      <Grid container spacing={1} style={{ padding: '8px 0px' }}>
        <Grid item md={6} xs={12}>
          <WorkOrderChart classes={classes} />
        </Grid>
        <Grid item md={6} xs={12}>
          <BillingInformationChart classes={classes} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ManPowerChart classes={classes} />
        </Grid>
        <Grid item md={6} xs={12}>
          <FieldNotesChart classes={classes} />
        </Grid>
        <Grid item md={6} xs={12}>
          <MaterialDetailsChart classes={classes} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ContractorProductionRate classes={classes} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ContractorDetails);
