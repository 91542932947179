import { createContractorStyle } from '../styles/theme.contractorLis';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';

const CreateContractorModal = ({ open, handleClose }) => {
  const classes = createContractorStyle();

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    firstName: yup.string().required('Full name is required'),
    companyName: yup.string().required('Address is required'),
    panNo: yup.string().required('Address is required'),
    billingAddress: yup.string().required('Address is required'),
    phoneNumber: yup.string().required('Address is required'),
    scopeOfWork: yup.string().required('Address is required'),
    password: yup.string().required('Address is required'),
    confirmPassword: yup.string().required('Address is required'),
    accountType: yup.string().required('Address is required'),
  });
  var formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      entityType: 0,
      companyName: '',
      panNo: '',
      billingAddress: '',
      GSTIN: '',
      aadharNo: '',
      phoneNumber: '',
      email: '',
      accountNumber: '',
      IFSCCode: '',
      accountType: '',
      scopeOfWork: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      className={classes.modal}
      onClose={handleClose}
      PaperProps={{ style: { width: 900, height: '90%' } }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add New Contractor</Typography>
          <CloseIcon className={classes.pointerCursor} onClick={handleClose} />
        </Grid>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="FIRST NAME*"
                  fullWidth
                  variant="filled"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="LAST NAME (Optional)"
                  fullWidth
                  variant="filled"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  select
                  label="ENTITY TYPE"
                  size="small"
                  style={{
                    fontFamily: 'Roboto',
                    '& .MuiFilledInput-inputMarginDense': {
                      paddingTop: '110px',
                    },
                  }}
                  variant="filled"
                  fullWidth
                  id="entityType"
                  name="entityType"
                  value={formik.values.entityType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.entityType &&
                    Boolean(formik.errors.entityType)
                  }
                  helperText={
                    formik.touched.entityType && formik.errors.entityType
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value={0} style={{ fontFamily: 'Roboto' }}>
                    Proprietor/ Orgranisation
                  </MenuItem>
                  <MenuItem value={1} style={{ fontFamily: 'Roboto' }}>
                    A
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Company Name/Proprietor's Name*"
                  fullWidth
                  variant="filled"
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>{' '}
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="PAN NO*"
                  fullWidth
                  variant="filled"
                  id="panNo"
                  name="panNo"
                  value={formik.values.panNo}
                  onChange={formik.handleChange}
                  error={formik.touched.panNo && Boolean(formik.errors.panNo)}
                  helperText={formik.touched.panNo && formik.errors.panNo}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="BILLING ADDRESS*"
                  fullWidth
                  variant="filled"
                  id="billingAddress"
                  name="billingAddress"
                  value={formik.values.billingAddress}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingAddress &&
                    Boolean(formik.errors.billingAddress)
                  }
                  helperText={
                    formik.touched.billingAddress &&
                    formik.errors.billingAddress
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="GSTIN (Optional)"
                  fullWidth
                  variant="filled"
                  id="GSTIN"
                  name="GSTIN"
                  value={formik.values.GSTIN}
                  onChange={formik.handleChange}
                  error={formik.touched.GSTIN && Boolean(formik.errors.GSTIN)}
                  helperText={formik.touched.GSTIN && formik.errors.GSTIN}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="AADHAR NO (Optional)"
                  fullWidth
                  variant="filled"
                  id="aadharNo"
                  name="aadharNo"
                  value={formik.values.aadharNo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.aadharNo && Boolean(formik.errors.aadharNo)
                  }
                  helperText={formik.touched.aadharNo && formik.errors.aadharNo}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>{' '}
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="PHONE NUMBER*"
                  fullWidth
                  variant="filled"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="EMAIL (Optional)"
                  fullWidth
                  variant="filled"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="ACCOUNT NUMBER (Optional)"
                  fullWidth
                  variant="filled"
                  id="accountNumber"
                  name="accountNumber"
                  value={formik.values.accountNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountNumber &&
                    Boolean(formik.errors.accountNumber)
                  }
                  helperText={
                    formik.touched.accountNumber && formik.errors.accountNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="IFSC CODE (Optional)"
                  fullWidth
                  variant="filled"
                  id="IFSCCode"
                  name="IFSCCode"
                  value={formik.values.IFSCCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.IFSCCode && Boolean(formik.errors.IFSCCode)
                  }
                  helperText={formik.touched.IFSCCode && formik.errors.IFSCCode}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>{' '}
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  select
                  label="ACCOUNT TYPE*"
                  size="small"
                  style={{
                    fontFamily: 'Roboto',
                    '& .MuiFilledInput-inputMarginDense': {
                      paddingTop: '110px',
                    },
                  }}
                  variant="filled"
                  fullWidth
                  id="accountType"
                  name="accountType"
                  value={formik.values.accountType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountType &&
                    Boolean(formik.errors.accountType)
                  }
                  helperText={
                    formik.touched.accountType && formik.errors.accountType
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value={0} style={{ fontFamily: 'Roboto' }}>
                    General Contractor/SubContractor/Labour
                  </MenuItem>
                  <MenuItem value={1} style={{ fontFamily: 'Roboto' }}>
                    A
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="SCOPE OF WORK"
                  size="small"
                  style={{
                    fontFamily: 'Roboto',
                    '& .MuiFilledInput-inputMarginDense': {
                      paddingTop: '110px',
                    },
                  }}
                  variant="filled"
                  fullWidth
                  id="scopeOfWork"
                  name="scopeOfWork"
                  value={formik.values.scopeOfWork}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scopeOfWork &&
                    Boolean(formik.errors.scopeOfWork)
                  }
                  helperText={
                    formik.touched.scopeOfWork && formik.errors.scopeOfWork
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem
                    value={0}
                    style={{ fontFamily: 'Roboto' }}
                  ></MenuItem>
                  <MenuItem
                    value={1}
                    style={{ fontFamily: 'Roboto' }}
                  ></MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="SET PASSWORD*"
                  fullWidth
                  variant="filled"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="CONFIRM PASSWORD*"
                  fullWidth
                  variant="filled"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonGreen}
                style={{ marginRight: 10 }}
              >
                Create Contractor
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonPurpleOpacity}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateContractorModal;
