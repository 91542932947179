import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

import { getProjects, projectReducer } from 'src/redux/projects';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import moment from 'moment';
import { getContractors, usersReducer } from 'src/redux/users';
import {
  createFieldNoteEntry,
  fieldNoteReducer,
  getFieldNoteCost,
  getFieldNoteList,
} from 'src/redux/fieldNote';
import toast, { Toaster } from 'react-hot-toast';
import {
  getBOQListGC,
  getScopeList,
  productivityReducer,
  rejectBOQProgress,
} from 'src/redux/productivity';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },

  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  input1: {
    display: 'none',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  inputFieldsContainer: {
    paddingRight: '1rem',
    // marginTop: 20,
  },

  bankAccountNumberField: {
    paddingRight: '1rem',
  },
  paddingRight: {
    paddingRight: '1rem',
  },
  commonStyleInput: {
    paddingBottom: '1.5rem',
  },
  imageCard: {
    width: '300px',
    height: '250px',
    backgroundColor: 'white',
    border: '1px solid #ddd',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  paddingLeft: {
    paddingLeft: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    inputFieldsContainer: {
      paddingRight: '0px',
    },
    bankAccountNumberField: {
      paddingRight: '0px',
      paddingBottom: '10px',
    },
    paddingRight: {
      paddingRight: '0',
    },
    imageCard: {
      width: '100%',
      padding: '10px',
    },
    paddingLeft: {
      paddingLeft: '0',
    },
  },
}));
const CreateFieldNote = (props) => {
  const {
    fromModal,
    closeModal,
    selectedRow,
    currentProjectProgress,
    openPreviousModal,
    reject,
  } = props;
  const [preview, setPreview] = useState(null);

  //! INSTANCES
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { contractorList } = useSelector(usersReducer);
  const { projectsList } = useSelector(projectReducer);
  const { loading, costList } = useSelector(fieldNoteReducer);
  const { scopeList } = useSelector(productivityReducer);

  //! LIFE CYCLE
  useEffect(() => {
    dispatch(getScopeList());
    dispatch(getProjects());
    if (selectedRow?.contractorId) {
      dispatch(
        getFieldNoteCost(
          projectsList?.filter((item) =>
            item.projectId === selectedRow
              ? selectedRow?.rejectObject.currentProject
              : currentProjectProgress?.projectId
          )[0] ?? '',
          selectedRow?.scopeOfWorkId ?? '',
          selectedRow?.contractorId
        )
      );
    }
  }, []);

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    ScopeOfWorkId: yup.string().required('scopeOfWork is required'),
    description: yup.string().required('description is required'),
    date: yup.string().required('date is required'),
    time: yup.string().required('time is required'),
    projectId: yup.string().required('Project is required'),
    contractorId: yup.string().required('Contractor is required'),
    location: yup.string().required('location is required'),
    // remarks: yup.string().required('Remarks is required'),
    // quantity: yup.string().required('Quantity is required'),
  });
  var formik = useFormik({
    initialValues: {
      ScopeOfWorkId: selectedRow?.scopeOfWorkId ?? '',
      description: selectedRow?.description ?? '',
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:MM'),
      contractorId: selectedRow?.contractorId ?? '',
      location: '',
      image: '',
      projectId:
        projectsList?.filter((item) =>
          selectedRow
            ? item.projectId === selectedRow?.rejectObject.currentProject
            : item.projectId === currentProjectProgress?.projectId
        )[0] ?? '',
      ContractorBOQProgressId: selectedRow?.contractorBOQProgressId ?? '',
      remarks: selectedRow?.remarks ?? '',
      quantity: selectedRow?.userRejectedValue ?? 0,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const checkArrayValues = Object.values(values);
      if (checkArrayValues.includes(' ')) {
        return;
      }
      const { date, time, projectId, ...rest } = values;

      const formData = new FormData();
      for (const key in rest) {
        if (rest.hasOwnProperty(key)) {
          formData.append(key, rest[key]);
        }
      }
      formData.append(
        'DateTime',
        moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
      );
      formData.append('ProjectId', projectId.projectId);

      if (fromModal) {
        const { currentProject, currentLabourContractor, ...rest } =
          selectedRow?.rejectObject;
        let rejectResp = await dispatch(rejectBOQProgress(rest));
        if (rejectResp.status === 400) {
          toast.error(rejectResp.data.title);
        } else if (rejectResp.status === 200) {
          await dispatch(getBOQListGC(currentProject, currentLabourContractor));
        }
      }
      let resp = await dispatch(createFieldNoteEntry(formData));
      if (resp?.status === 200) {
        // reject();
        setPreview(null);
        toast.success('Field Note Created Successfully!', {
          position: 'top-right',
        });
        if (fromModal) {
          // reject();

          return closeModal();
        }
        dispatch(getFieldNoteList());
        navigate('/app/field-notes');
      } else {
        toast.error('Error in creating field note', {
          position: 'top-right',
        });
      }
    },
  });

  const handleImage = (e) => {
    // let selectedImage = e.target.files[0];

    if (e.target.files.length) {
      setPreview(URL?.createObjectURL(e.target.files[0]));
      formik.setFieldValue('image', e.target.files[0], true);
      // setRotation(0); // Reset rotation angle
    }
  };
  return (
    <Grid
      container
      style={{
        padding: '0 10px',
      }}
    >
      <Grid container justify="space-between" style={{ marginBottom: '2rem' }}>
        <Grid item style={{ fontWeight: 700, fontSize: '16px' }}>
          <Typography variant="h6">Create Field Note</Typography>
        </Grid>
        {fromModal && (
          <Grid item>
            <CloseIcon
              onClick={() => closeModal()}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        )}
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="flex-start"
          style={{
            marginTop: '1rem',
          }}
        >
          <Grid
            item
            md={3}
            xs={12}
            // className={classes.paddingLeft}
            container
            direction="column"
          >
            {/* Profile */}
            <Grid item>
              <input
                accept="image/*"
                className={classes.input1}
                id="icon-button-file"
                type="file"
                onChange={handleImage}
              />

              <label
                htmlFor="icon-button-file"
                className={classes.ImagePlaceHolder}
              >
                {preview ? (
                  <img
                    src={`${preview}`}
                    alt="project url"
                    style={{
                      width: '300px',
                      height: '250px',
                      borderRadius: '10px',
                      marginBottom: '10px',
                    }}
                  />
                ) : (
                  <Grid
                    container
                    className={classes.imageCard}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Grid item>
                        <ImageIcon
                          style={{
                            color: 'RGB(183, 194, 202)',
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          color: 'RGB(151, 196, 87)',
                          fontSize: '12px',
                        }}
                      >
                        +{' '}
                        <span style={{ textDecoration: 'underline' }}>
                          UPLOAD PICTURE
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </label>
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                }}
              >
                {' '}
                {formik.touched.image && formik.errors.image}
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={8}
            xs={12}
            style={{
              padding: '10px 0px 30px 10px',
            }}
            alignItems="space-between"
            className={`${classes.inputFieldsContainer} ${classes.marginLeft}`}
          >
            <Grid item xs={12} className={classes.commonStyleInput} container>
              {fromModal ? (
                <Grid item xs={12} className={classes.commonStyleInput}>
                  <Typography>BOQ # : {selectedRow?.boqId}</Typography>
                </Grid>
              ) : null}
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                <TextField
                  disabled={selectedRow}
                  select
                  label="SELECT PROJECT"
                  size="small"
                  style={{ fontFamily: 'Roboto' }}
                  // placeholder='----'
                  variant="filled"
                  fullWidth
                  // style={{ width: "300px" }}
                  // className={classes.commonStyleInput}
                  id="projectId"
                  name="projectId"
                  value={formik.values.projectId}
                  onChange={(data) => {
                    formik.setFieldValue('projectId', data.target.value);
                    if (data.target.value !== 'Select Project') {
                      dispatch(getContractors(data.target.value?.projectId));
                      // dispatch(
                      //   getFieldNoteCost(
                      //     data.target.value?.projectId,
                      //     formik.values.ScopeOfWorkId === ''
                      //       ? 0
                      //       : formik.values.ScopeOfWorkId,
                      //     formik.values.contractorId === ''
                      //       ? 0
                      //       : formik.values.contractorId
                      //   )
                      // );
                    }
                  }}
                  error={
                    formik.touched.projectId && Boolean(formik.errors.projectId)
                  }
                  helperText={
                    formik.touched.projectId && formik.errors.projectId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value="Select Project">Select Project</MenuItem>

                  {projectsList?.map((project) => (
                    <MenuItem
                      key={project.projectId}
                      value={project}
                      style={{ fontFamily: 'Roboto' }}
                      // onClick={() => handleProjectChange(project)}
                    >
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                // container
                item
                md={6}
                xs={12}
                // className={classes.commonStyleInput}
              >
                <TextField
                  disabled={selectedRow}
                  select
                  label="ASSIGN TO"
                  size="small"
                  style={{ fontFamily: 'Roboto' }}
                  // placeholder='----'
                  variant="filled"
                  fullWidth
                  // style={{ width: "300px" }}
                  className={classes.commonStyleInput}
                  id="contractorId"
                  name="contractorId"
                  value={formik.values.contractorId}
                  onChange={(e) => {
                    formik.setFieldValue('contractorId', e.target.value);
                    // dispatch(
                    //   getFieldNoteCost(
                    //     formik.values.projectId?.projectId === ''
                    //       ? 0
                    //       : formik.values.projectId,
                    //     formik.values.ScopeOfWorkId === ''
                    //       ? 0
                    //       : formik.values.ScopeOfWorkId,

                    //     e.target.value
                    //   )
                    // );
                  }}
                  error={
                    formik.touched.contractorId &&
                    Boolean(formik.errors.contractorId)
                  }
                  helperText={
                    formik.touched.contractorId && formik.errors.contractorId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value="">Select Contractor</MenuItem>

                  {formik.values.projectId === 'Select Project'
                    ? []
                    : contractorList?.length > 0 &&
                      contractorList
                        .filter(
                          (item) => item.leadTypeId === 'LabourContractor'
                        )
                        .map((item) => (
                          <MenuItem
                            key={item.userId}
                            value={item.userId}
                            style={{ fontFamily: 'Roboto' }}
                          >
                            {item.fullName}
                          </MenuItem>
                        ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.commonStyleInput}
              // justify="space-between"
              // alignItems="center"
            >
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                {' '}
                <TextField
                  disabled={selectedRow}
                  select
                  label="SCOPE OF WORK"
                  size="small"
                  style={{ fontFamily: 'Roboto' }}
                  variant="filled"
                  fullWidth
                  id="ScopeOfWorkId"
                  name="ScopeOfWorkId"
                  value={formik.values.ScopeOfWorkId}
                  onChange={(e) => {
                    formik.setFieldValue('ScopeOfWorkId', e.target.value);
                    dispatch(
                      getFieldNoteCost(
                        formik.values.projectId?.projectId,
                        e.target.value,
                        formik.values.contractorId
                      )
                    );
                  }}
                  error={
                    formik.touched.ScopeOfWorkId &&
                    Boolean(formik.errors.ScopeOfWorkId)
                  }
                  helperText={
                    formik.touched.ScopeOfWorkId && formik.errors.ScopeOfWorkId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value="">Select Scope</MenuItem>

                  {scopeList?.length > 0 &&
                    scopeList?.map((sow, index) => (
                      <MenuItem
                        key={sow.scopeOfWorkId}
                        value={sow.scopeOfWorkId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {sow.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                {fromModal ? (
                  <TextField
                    disabled={selectedRow}
                    size="small"
                    // label="COST CODE"
                    fullWidth
                    variant="filled"
                    id="ContractorBOQId"
                    name="ContractorBOQId"
                    defaultValue={selectedRow?.boqCode}
                    value={selectedRow?.boqCode}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                ) : (
                  <TextField
                    disabled={selectedRow}
                    select
                    label="COST CODE"
                    size="small"
                    style={{ fontFamily: 'Roboto' }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}
                    className={classes.commonStyleInput}
                    id="ContractorBOQId"
                    name="ContractorBOQId"
                    value={formik.values.ContractorBOQId}
                    onChange={(e) => {
                      if (e.target.value) {
                        formik.setFieldValue(
                          'ContractorBOQId',
                          e.target.value.ContractorBOQId
                        );
                        formik.setFieldValue(
                          'quantity',
                          e.target.value.quantity
                        );
                        formik.setFieldValue(
                          'description',
                          e.target.value.description
                        );
                      } else {
                        formik.setFieldValue('ContractorBOQId', '');
                        formik.setFieldValue('quantity', 0);
                        formik.setFieldValue('description', '');
                      }
                    }}
                    error={
                      formik.touched.ContractorBOQId &&
                      Boolean(formik.errors.ContractorBOQId)
                    }
                    helperText={
                      formik.touched.ContractorBOQId &&
                      formik.errors.ContractorBOQId
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="">Select Cost Code</MenuItem>

                    {costList?.length > 0
                      ? costList.map((item) => (
                          <MenuItem
                            key={item.contractorBOQId}
                            value={item}
                            style={{ fontFamily: 'Roboto' }}
                          >
                            {item.costCode}
                          </MenuItem>
                        ))
                      : []}
                  </TextField>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              className={classes.commonStyleInput}
              // justify="space-between"
              // alignItems="center"
            >
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                <TextField
                  disabled={selectedRow}
                  inputProps={{ style: { fontFamily: 'Roboto' } }}
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  variant="filled"
                  label="DESCRIPTION"
                  size="small"
                  fullWidth
                  style={{
                    width: '100%',
                    borderRadius: '4px',
                    backgroundColor: '#F9F9F9',
                    fontFamily: 'Roboto',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />{' '}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled={selectedRow}
                  size="small"
                  label="QUANTITY"
                  fullWidth
                  variant="filled"
                  type="number"
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.ifscCode ?? 'N/A'}
                  id="quantity"
                  name="quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.quantity && Boolean(formik.errors.quantity)
                  }
                  helperText={formik.touched.quantity && formik.errors.quantity}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              // className={classes.commonStyleInput}
              justify="center"
            >
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                {' '}
                <TextField
                  // disabled={selectedRow}
                  size="small"
                  label="LOCATION"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.ifscCode ?? 'N/A'}
                  id="location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.commonStyleInput}>
                {' '}
                <TextField
                  // disabled={selectedRow}
                  size="small"
                  label="REMARKS"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.ifscCode ?? 'N/A'}
                  id="remarks"
                  name="remarks"
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.remarks && Boolean(formik.errors.remarks)
                  }
                  helperText={formik.touched.remarks && formik.errors.remarks}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              // className={classes.commonStyleInput}
              justify="center"
            >
              <Grid item md={6} xs={12} className={classes.paddingRight}>
                {' '}
                <TextField
                  disabled={selectedRow}
                  size="small"
                  type="date"
                  label="COMPLETION DATE"
                  fullWidth
                  variant="filled"
                  id="date"
                  name="date"
                  // className={classes.commonStyleInput}
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.bankName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                  // inputProps={{
                  //   max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                  //   min: moment().subtract(58, "years").format("YYYY-MM-DD"),
                  // }}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.commonStyleInput}>
                {' '}
                <TextField
                  disabled={selectedRow}
                  size="small"
                  type="time"
                  label="COMPLETION TIME"
                  fullWidth
                  variant="filled"
                  id="time"
                  name="time"
                  value={formik.values.time}
                  onChange={formik.handleChange}
                  error={formik.touched.time && Boolean(formik.errors.time)}
                  helperText={formik.touched.time && formik.errors.time}
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.bankName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                  // inputProps={{
                  //   max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                  //   min: moment().subtract(58, "years").format("YYYY-MM-DD"),
                  // }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              justify="flex-end"
              // style={{ marginTop: "1rem" }}
            >
              <Grid item style={{ marginRight: '1rem' }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  style={{
                    background: '#81B734',
                    color: '#fff',
                    // borderRadius: '10px',
                    padding: '10px 1.5rem',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                  }}
                  disabled={loading}
                  // onClick={() => formik.submitForm()}
                >
                  {loading ? 'loading...' : ' Create Note'}
                </Button>
              </Grid>
              <Grid item>
                {fromModal ? null : (
                  <Link
                    to={fromModal ? './' : '..'}
                    relative="path"
                    style={{ color: 'black' }}
                    // onClick={() => dispatch(setWorkerForDetail(null))}
                  >
                    <Button
                      // type="submit"
                      size="small"
                      variant="contained"
                      style={{
                        background: 'RGB(156, 117, 240)',
                        color: '#fff',
                        padding: '10px 2.5rem',
                        fontSize: '12px',
                        textTransform: 'capitalize',
                      }}
                    >
                      Cancel
                    </Button>
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Toaster
          toastOptions={{
            duration: 10000,
          }}
        />
      </form>
    </Grid>
  );
};

export default CreateFieldNote;
